import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/grid'
import { useTheme } from 'styled-components'
import { TbMessageChatbot } from 'react-icons/tb'
import { IoArrowUpCircleOutline } from 'react-icons/io5'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Tooltip from '../../atoms/Tooltip'

const MessageQuotaBox = ({ quota, remaining, onClickTopUp }) => {
  const theme = useTheme()
  return (
    <Flex alignItems="center">
      <TbMessageChatbot size={24} />
      <Text variant="body1">: </Text>
      <Box ml="0.4rem">
        <Text
          variant="h3"
          id="message_remaining_count"
          bold
          {...(remaining <= 0 ? {
            palette: 'error',
            className: 'blink',
          } : {})}
        >
          {remaining || 0}
        </Text>
        {remaining <= 0 && (
          <Tooltip
            place="left"
            anchorId="message_remaining_count"
            defaultIsOpen
          >
            <Text palette="white">放心！今晚會加返比你！</Text>
          </Tooltip>
        )}
      </Box>
      {/* <Text
        variant="body1"
        style={{ marginLeft: '0.4rem' }}
      >
        {`/ ${quota || 0}`}
      </Text> */}
      <Button
        padding={0}
        variant="text"
        id="top_up_btn"
        onClick={onClickTopUp}
        style={{ marginLeft: '0.2rem' }}
      >
        <IoArrowUpCircleOutline size={24} />
      </Button>
      {remaining <= 0 && (
        <Tooltip
          place="bottom-end"
          color={theme.palette.secondary[2]}
          anchorId="top_up_btn"
          defaultIsOpen
        >
          <Text palette="white">{'唔夠用？\n立即增值亦得！'}</Text>
        </Tooltip>
      )}
    </Flex>

  )
}

MessageQuotaBox.propTypes = {
  quota: PropTypes.number,
  remaining: PropTypes.number,
  onClickTopUp: PropTypes.func,
}

MessageQuotaBox.defaultProps = {
  quota: 0,
  remaining: 0,
  onClickTopUp: () => {},
}

export default MessageQuotaBox
