import React from 'react'
import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import { size } from 'styled-theme'
import Text from '../../atoms/Text'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 3rem 3rem;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const StyledContentBox = styled(ContentBox)`
  padding: 1rem;
`

const ExplorePage = () => {
  return (
    <StyledViewport>
      <StyledContentBox>
        <Flex width={1} height="100%" flexDirection="column" alignItems="center" justifyContent="center" p="1rem" style={{ minWidth: 240 }}>
          <Text variant="h2" align="center">
            ⚠️新功能⚠️<br />即將推出<br />
          </Text>
          <Flex mt="2rem" width={1} maxWidth="8rem" />
        </Flex>
      </StyledContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default ExplorePage
